<template>
	<div>
		<div :style="{
			'paddingTop':plugins_data.style.padding_top/2+'px',
			'paddingBottom':plugins_data.style.padding_bottom/2+'px',
			'paddingLeft':plugins_data.style.padding_left/2+'px',
			'paddingRight':plugins_data.style.padding_left/2+'px'
		}">
			<div v-if="plugins_data.data.limit==0">
				<div class="nolimit">请选择需要展示图文的数量</div>
			</div>
			<div v-else v-loading="loading">
				<div class="listbox" v-for="(item,index) in list" :key="index" :style="{'margin-bottom':plugins_data.style.margin_botton/2+'px','background':plugins_data.style.background}">
					<div class="leftbox">
						<el-image class="img" :src="item.image" fit="cover"></el-image>
					</div>
					<div class="rightbox">
						<div class="title" :style="{'color':item.title_color}">{{item.title||'请输入列表标题'}}</div>
						<div class="text" :style="{'color':item.content_color}">{{item.intro||'请输入列表内容'}}</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'graphicView',
	data() {
		return {
			limit:0,
			list:[],
			loading:false,
		};
	},
	props: {
		plugins_data: { type: Object },
	},
	watch: {
		'plugins_data.data.limit' (val) {
		   this.limit = val
		   if(val>0) this.getList();
		}
	},
	mounted() {
		if(this.plugins_data.data.limit>0){
			this.getList()
		}
	},
	methods:{
		getList(){
			this.totalLoading = true
			let data = {
				status:1,
				limit:this.plugins_data.data.limit
			}
			this.loading = true
			this.axios.get('/api/public/admin/articles',{params:data}).then(res=>{
				this.loading = false
				if(res.code==200){
					this.list = res.data.data
				}else{
					eltips(res.msg,'error')
				}
			})
		},
	}
};
</script>

<style scoped lang="less">
	.nolimit{
		display: flex;
		align-items: center;
		padding: 20px;
	}
	
	.listbox{
		display: flex;
		align-items: flex-start;
		&:last-child{
			margin-bottom: 0 !important;
		}
		.leftbox{
			flex: 0 0 120px;
			width: 120px;
			height: 120px;
			.img{
				width: 100%;
				height: 100%;
			}
		}
		.rightbox{
			margin-left: 10px;
			padding-right: 10px;
			.title{
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				font-weight: bold;
			}
			.text{
				margin-top: 4px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
				font-size: 12px;
			}
		}
	}
</style>
