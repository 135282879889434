var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"storechoose"}},[_c('div',{staticClass:"box",style:({
		'paddingLeft':_vm.plugins_data.style.padding_left/2+'px',
		'paddingRight':_vm.plugins_data.style.padding_left/2+'px',
		'paddingTop':_vm.plugins_data.style.padding_top/2+'px',
		'paddingBottom':_vm.plugins_data.style.padding_top/2+'px',
		'marginLeft':_vm.plugins_data.style.margin_left/2+'px',
		'marginRight':_vm.plugins_data.style.margin_left/2+'px',
		'marginTop':_vm.plugins_data.style.margin_top/2+'px',
		'marginBottom':_vm.plugins_data.style.margin_bottom/2+'px',
		'background':_vm.plugins_data.style.box_bg_type===1?'rgba(0,0,0,0)':_vm.plugins_data.style.box_background,
		'borderRadius':_vm.plugins_data.style.box_radius_top/2+'px '+_vm.plugins_data.style.box_radius_top/2+'px '+_vm.plugins_data.style.box_radius_bottom/2+'px '+_vm.plugins_data.style.box_radius_bottom/2+'px',
	})},[_c('div',{staticClass:"left"},[_c('el-image',{staticClass:"icon",attrs:{"src":_vm.plugins_data.data.left_icon,"fit":"cover"}}),_c('div',{staticClass:"text",style:({'color': _vm.plugins_data.style.text_color})},[_vm._v(_vm._s(_vm.userinfo.store_name))]),_c('i',{staticClass:"el-icon-arrow-right",style:({'color': _vm.plugins_data.style.text_color})})],1),_c('div',{staticClass:"right"},[_c('el-image',{staticClass:"icon",attrs:{"src":_vm.plugins_data.data.msg_icon,"fit":"cover"}}),_c('el-image',{staticClass:"icon",attrs:{"src":_vm.plugins_data.data.code_icon,"fit":"cover"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }