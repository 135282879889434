<template>
  <div
    class="bodybox"
    :style="{
      height: 'calc(' + height + ' - ' + plugins_data.style.top/2 + 'px)',
    }"
  >
    <div
      class="menubox"
      ref="ele"
      :style="{
        left: plugins_data.style.padding_left/2 + 'px',
        right: plugins_data.style.padding_left /2+ 'px',
        padding:
          plugins_data.style.padding_top / 2 +
          'px ' +
          plugins_data.style.padding_left / 2 +
          'px ' +
          plugins_data.style.padding_bottom / 2 +
          'px',
        backgroundColor: plugins_data.style.background,
        borderRadius:
          plugins_data.style.border_radius_top / 2 +
          'px ' +
          plugins_data.style.border_radius_top / 2 +
          'px ' +
          plugins_data.style.border_radius_bottom / 2 +
          'px ' +
          plugins_data.style.border_radius_bottom / 2 +
          'px',
      }"
    >
      <div
        class="box"
        :style="{ width: 100 / plugins_data.cofig.menu_number + '%' }"
        v-for="(item, index) in plugins_data.menus"
        :key="index"
      >
        <el-image
          class="image"
          :style="{
            borderRadius:
              plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px',
          }"
          :src="item.images || require('assets/images/not_menu.png')"
          fit="cover"
        ></el-image>
        <div class="text" :style="{ color: plugins_data.style.color }">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "meuns",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      width: 33.33,
      list: [],
      height: "0px",
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  watch: {
    plugins_data: {
      handler: function () {
        this.$nextTick(() => {
          this.initHeight();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.initHeight();
  },
  methods: {
    initHeight() {
      //获取元素样式值,为元素ref="ele"(在样式里面写死了的高度)
      this.height = window.getComputedStyle(this.$refs.ele).height;
    },
  },
};
</script>


<style scoped lang="less">
.bodybox {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.menubox {
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  .box {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    .image {
      width: 44px;
      height: 44px;
      display: block;
      margin: auto;
    }
    .text {
      margin-top: 4px;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
