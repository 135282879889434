<template>
	<div class='plugins'>
		<div class="imgbox" :style="{
			'paddingTop':plugins_data.style.padding_top/2+'px',
			'paddingBottom':plugins_data.style.padding_bottom/2+'px',
			'paddingLeft':plugins_data.style.padding_left/2+'px',
			'paddingRight':plugins_data.style.padding_left/2+'px'
		}">
			<img class="img" v-for="(item,index) in plugins_data.imgs" :key="index" :src="item.url||require('assets/images/img.png')" :style="{
				'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
				'marginBottom':plugins_data.style.img_margin/2+'px'
			}">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pictureView',
		data() {
			return {
				
			};
		},
		props: {
			plugins_data: { type: Object },
		},
		methods:{
			
		}
	};
</script>

<style lang="less" scoped>
	.imgbox{
		.img{
			display: block;
			width: 100%;
			&:last-child{
				margin-bottom: 0 !important;
			}
		}
	}
</style>
