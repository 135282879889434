<!-- data窗口 -->
<template>
  <div class="plugins">
    <!-- 组件style设置 -->
    <div>
      <div class="headsty">组件样式</div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">组件背景:</div>
        <el-radio-group v-model="plugins_data.style.box_bg_type">
          <el-radio :label="2">颜色</el-radio>
          <el-radio :label="1">透明</el-radio>
        </el-radio-group>
      </div>
      <div class="box" v-if="plugins_data.style.box_bg_type == 2">
        <div class="label">组件背景色:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.box_background"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.box_background"
          style="width: 100px; margin-right: 6px"
        ></el-input>
        <el-button
          plain
          size="small"
          @click="plugins_data.style.box_background = '#FFF5F3'"
          >重置</el-button
        >
      </div>
      <div class="box">
        <div class="label">标题文字:</div>
        <el-input
          type="text"
          v-model="plugins_data.style.title_text"
        ></el-input>
      </div>
      <div class="box">
        <div class="label">背景图:</div>
        <div class="imagebox" @click="openUpload(1)">
          <el-image
            class="image"
            v-if="plugins_data.backgroundImg"
            :src="plugins_data.backgroundImg"
            fit="cover"
          ></el-image>
          <div class="addbox" v-else>
            <i class="el-icon-plus addicon"></i>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="label">广告图:</div>
        <div class="imagebox" @click="openUpload(2)">
          <el-image
            class="image"
            v-if="plugins_data.advertUrl"
            :src="plugins_data.advertUrl"
            fit="cover"
          ></el-image>
          <div class="addbox" v-else>
            <i class="el-icon-plus addicon"></i>
          </div>
        </div>
      </div>
      <div class="box" v-if="plugins_data.style.box_bg_type == 2">
        <div class="label">文字颜色:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.title_color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.title_color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
        <el-button
          plain
          size="small"
          @click="plugins_data.style.title_color = '#222222'"
          >重置</el-button
        >
      </div>
      <div class="box">
        <div class="label">标题右侧:</div>
        <el-radio-group v-model="plugins_data.style.title_right">
          <el-radio :label="true">显示</el-radio>
          <el-radio :label="false">隐藏</el-radio>
        </el-radio-group>
      </div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_margin_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_margin_top"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_margin_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_margin_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_margin_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_margin_bottom"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_radius_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_radius_top"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_radius_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_radius_bottom"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <div>
      <div class="headsty">内容样式</div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">内容背景:</div>
        <el-radio-group v-model="plugins_data.style.content_bg_type">
          <el-radio :label="2">颜色</el-radio>
          <el-radio :label="1">透明</el-radio>
        </el-radio-group>
      </div>
      <div class="box" v-if="plugins_data.style.content_bg_type == 2">
        <div class="label">内容背景色:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.content_background"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.content_background"
          style="width: 100px; margin-right: 6px"
        ></el-input>
        <el-button
          plain
          size="small"
          @click="plugins_data.style.content_background = '#FFFFFF'"
          >重置</el-button
        >
      </div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">上下边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.content_padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.content_padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.content_padding_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.content_padding_top"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.content_radius_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.content_radius_top"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.content_radius_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.content_radius_bottom"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

   

    <!-- 添加商品弹窗 -->
    <el-dialog
      id="stroeaddgoods"
      title="活动选择器"
      :visible.sync="addgoodsshow"
      :modal-append-to-body="false"
      width="960px"
    >
      <div class="goodsbox" v-loading="loading">
        <div class="headbox">
          <el-form :inline="true">
            <el-form-item label="活动名称">
              <el-input v-model="keyword" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="goodSearch"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="dialogtabel">
          <el-table
            v-loading="loading"
            :data="goodslist"
            style="width: 100%"
            stripe
            :header-cell-style="{ background: '#fafafa', color: '#999' }"
          >
            <el-table-column prop="name" label="活动名称"></el-table-column>
            <el-table-column label="操作" width="80" align="center">
              <template slot-scope="scope">
                <div
                  class="choosebtn"
                  :class="{ choose: choose.id == scope.row.id }"
                  @click="choosegoods(scope.row)"
                >
                  {{ choose.id == scope.row.id ? "已选" : "选择" }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pages" v-if="total > 5">
          <el-pagination
            background
            :hide-on-single-page="true"
            @current-change="goodChangepage"
            :current-page="page"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addgoodsshow = false">取 消</el-button>
        <el-button type="primary" @click="confirmgoods">确 定</el-button>
      </span>
    </el-dialog>
    <xsk-manage
      :managehide.sync="imgshow"
      is_rest
      @confirm="pickimg"
    ></xsk-manage>
  </div>
</template>

<script>
import { eltips } from "@/util/util.js";
import draggable from "vuedraggable";
export default {
  name: "goodsgroup",
  components: { draggable },
  data() {
    return {
      upload_type:'',
      imgshow: false,
      addgoodsshow: false,
      goodslist: [],
      choose: [],
      choose_id: [],
      keyword: "",
      page: 1,
      total: 1,
      loading: true,
      replace_type: 1, //1、添加 2、替换
      replace_index: "",
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  created() {
    this.getgoodslist();
  },
  methods: {
    openUpload(type) {
      this.upload_type = type;
      this.imgshow = true;
    },
    //选择图片
    pickimg(e) {
      if (e.imgobjs.length > 0) {
        if (this.upload_type == 1) {
        this.plugins_data.backgroundImg = e.imgobjs[0].qiniu_url;
        }
        if (this.upload_type == 2) {
        this.plugins_data.advertUrl = e.imgobjs[0].qiniu_url;
        }
      }
    },
    choosegoods(item) {
      this.choose = { ...item };
    },
    //确认选择
    confirmgoods() {
      if (this.choose) {
        this.plugins_data.activity = {
          id: this.choose.id,
          name: this.choose.name,
          start_date: this.choose.start_date,
          end_date: this.choose.end_date,
        };
      } else {
        this.plugins_data.activity = {
          id: "",
          name: "",
          start_date: "",
          end_date: "",
        };
      }

      this.addgoodsshow = false;
    },
    //删除商品
    delgoods(index) {
      this.plugins_data.activity = "";
    },
    //替换
    toreplace(item) {
      this.choose = { ...item };
      this.addgoodsshow = true;
    },
    //添加
    showdialog() {
      this.choose = [];
      this.choose_id = [];
      this.plugins_data.goods.forEach((item) => {
        this.choose.push(item);
        this.choose_id.push(item.id);
      });
      this.replace_type = 1;
      this.addgoodsshow = true;
    },
    //查询
    goodSearch() {
      this.page = 1;
      this.getgoodslist();
    },
    //页码切换事件
    changepage(e) {
      this.page = e;
      this.getgoodslist();
    },
    goodChangepage(e) {
      this.page = e;
      this.getgoodslist();
    },
    //获取商品列表
    getgoodslist() {
      let data = {
        page: this.page,
        keyword: this.keyword,
        valid: 1,
        limit: 5,
      };
      this.loading = true;
      this.axios
        .get("/api/retail/admin/seckill/list", { params: data })
        .then((res) => {
          this.total = res.data.total;
          this.loading = false;
          if (res.code == 200) {
            this.goodslist = res.data.data;
          }
        });
    },
  },
};
</script>

<style>
#plugins .box .el-input__inner {
  border-radius: 0 !important;
}
#stroeaddgoods .el-dialog__header {
  padding: 14px 20px;
  border-bottom: 1px solid #e9edef;
  font-weight: bold;
  font-size: 16;
}
#stroeaddgoods .el-dialog__headerbtn {
  top: 14px;
}
#stroeaddgoods .el-dialog__body {
  padding: 10px 20px;
  border-bottom: 1px solid #e9edef;
}
#stroeaddgoods .el-dialog__footer {
  text-align: center;
}
</style>
<style scoped lang="less">
@import "../../style/pluginsCommon.css";

.imagebox {
  //   flex: 0 0 80px;
  width: 217px;
  height: 120px;
  border: 1px dashed #f0f0f0;
  .image {
    width: 100%;
    height: 100%;
  }
  .addbox {
    width: 100%;
    height: 100%;
    border: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .addicon {
      font-size: 20px;
      color: #1989fa;
    }
    .addtext {
      margin-top: 4px;
      font-size: 12px;
      color: #999999;
    }
  }
}
.goodtabel {
  border: 1px solid #e9edef;
  margin-bottom: 20px;
  .tabelstyle {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: default;
    .goodsname {
      width: 40%;
      padding-left: 12px;
    }
    .price {
      width: 30%;
      text-align: center;
    }
    .btnbox {
      width: 30%;
      text-align: center;
    }
  }
  .tabelhead {
    background: rgb(244, 246, 248);
    font-weight: bold;
    border-bottom: 1px solid #e9edef;
  }
  .nodata {
    justify-content: center;
    border-bottom: 1px solid #e9edef;
  }
  .addbtn {
    justify-content: center;
    color: #2d8cf0;
    span {
      cursor: pointer;
    }
  }
  .gooslist {
    border-bottom: 1px solid #e9edef;
    justify-content: center;
    height: 60px;
    .goodsname {
      display: flex;
      align-items: center;
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .price {
      font-size: 12px;
    }
  }
}

.dialogtabel {
  .icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    margin-right: 6px;
  }
  .name {
    width: 394px;
    vertical-align: top;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .choosebtn {
    width: 60px;
    line-height: 32px;
    border-radius: 2px;
    color: #2d8cf0;
    border: 1px solid #c3c3c3;
    cursor: pointer;
    &:hover {
      border-color: #2d8cf0;
    }
  }
  .choose {
    background: #2d8cf0;
    color: #ffffff;
    border-color: #2d8cf0;
  }
}

.pages {
  text-align: center;
  background: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #ededed;
}
</style>
