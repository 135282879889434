<template>
	<div class="plugins">
		<div>
			<div class="headsty">内容</div>
			<div class="box">
				<tinymce-editor style="width: 100%;" :id="'tinymce'" ref="editor" :height="500" v-model="plugins_data.data" :disabled="false" />
			</div>
		</div>
		<div class="linesty"></div>
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">背景颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background"></el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">内边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.text_padding" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.text_padding">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	import TinymceEditor from "@/components/TinymceEditor/TinymceEditor";	
export default {
	name: 'richtext',
	components: { TinymceEditor },
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		
	}
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";

</style>
