<template>
	<div id="xskSku2">
		<div class="headbox">
			<div class="specbox" v-for="(item, index) in specs" :key="index">
				<i v-if="index != 0" class="closeicon el-icon-error" @click="delspec(index)"></i>
				<div class="title"><el-input style="width: 160px;" v-model="item.name" placeholder="如颜色,尺码,大小"></el-input></div>
				<div class="value">
					<div class="icons"></div>
					<div class="inputbox" v-for="(values, i) in item.spec_value" :key="i">
						<el-input v-model="item.spec_value[i]" placeholder="请填写自规格,如L,M"></el-input>
						<i v-if="i != 0" class="close el-icon-circle-close" @click="delvalue(index, i)"></i>
					</div>
					<div class="addbtn inputbox" @click="addvalue(index)">+添加规格值</div>
				</div>
			</div>
		</div>
		
		<div class="addbox"><span @click="addspec">+添加规格项</span></div>
		<div class="allset">
			<span style="margin-right: 20px;">批量设置:</span>
			<el-input class="plinput" v-model="set_sale_price" placeholder="售卖价"></el-input>		
			<el-input class="plinput" v-model="set_line_price" placeholder="划线价"></el-input>			
			<el-input class="plinput" v-model="set_supply_price" placeholder="供货价格"></el-input>
			<el-input class="plinput" v-model="set_purchase_price" placeholder="进货价格"></el-input>
			<el-input class="plinput" v-model="set_stock" placeholder="库存"></el-input>
			<el-input class="plinput" v-model="set_bar_code" placeholder="条形码"></el-input>
			<el-button style="margin-right: 20px;" type="primary" size="small" plain @click="setall">应用</el-button>
		</div>
		
		<div class="tablebox">
			<el-table :data="tableArr" style="width: 100%">
				<el-table-column v-for="(item, index) in specs" :key="index" :prop="'key' + index"  :label="item.name || '--'"></el-table-column>
				<el-table-column label="售卖价">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.sale_price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="划线价">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.line_price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="供货价格">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.supply_price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="进货价格">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.purchase_price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="库存">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.stock"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="条形码">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.bar_code"></el-input>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {eltips,SpecCartesian} from '@/util/util.js'
	export default {
		name: 'xskSku2',
		data() {
			return {
				// 规格数组
				specs: [{ name: '', spec_value: [''] }],
				set_sale_price:'',
				set_line_price:'',
				set_supply_price:'',
				set_purchase_price:'',
				set_stock:'',
				set_bar_code:'',
				tableArr: [{ bar_code: '', line_price: '', sale_price: '', stock: '', supply_price:'',purchase_price:'',title0: '--',key0: '--', }],
			};
		},
		created() {
			
		},
		props: {
			//组合后规格
			stocks: {
				type:Array,
				default:()=>[],
			},
			//规格
			specinfo: {
				type:Array,
				default:()=>[],
			},
			//是否禁用
			is_disabled:{
				type:Boolean,
				default:false
			}
		},
		watch: {
			stocks: {
				handler(val) {
					if(val.length>0){
						this.tableArr = JSON.parse(JSON.stringify(val)) 
					}
				},
				deep: true,
				immediate: true
			},
			specinfo:{
				handler(val) {
					if(val.length>0){
						this.specs = JSON.parse(JSON.stringify(val)) 
					}
				},
				deep: true,
				immediate: true
			},
			specs: {
				deep: true,
				immediate: true,
				handler: function(newV, oldV) {
					
					
					let arr = SpecCartesian(JSON.parse(JSON.stringify(newV)));
					
					let newarr = [];
					arr.forEach(item => {
						let obj = {};
						let spec = item.split(',');
						let specname = ''
						spec.forEach((s, index) => {
							obj['key' + index] = s;
							if(specname){
								specname = specname+'-'+s
							}else{
								specname = s
							}
						});
						
						this.specs.forEach((val, i) => {
							
							obj['title' + i] = val.title;
						});
						
						obj.name = specname
						obj.sale_price = '';
						obj.line_price = '';
						obj.supply_price = '';
						obj.purchase_price = '';
						obj.stock = '';
						obj.bar_code = '';
						
						this.tableArr.forEach(arrs=>{
							if(arrs.name===obj.name){
								console.log('检测到存在的sku',arrs)
								obj.sale_price = arrs.sale_price
								obj.line_price = arrs.line_price
								obj.supply_price = arrs.supply_price
								obj.purchase_price = arrs.purchase_price
								obj.stock = arrs.stock
								obj.bar_code = arrs.bar_code
							}
						})
						
						newarr.push(obj);
					});
					
					console.log(this.tableArr,123)
					
					this.tableArr = newarr;
					this.$emit('changeData',{
						spec:this.specs,
						spec_result:this.tableArr
					})
				}
			}
		},
		methods: {
			//批量设置
			setall(){
				this.tableArr.forEach(item=>{
					if(this.set_sale_price){
						item.sale_price = this.set_sale_price
					}
					
					if(this.set_line_price){
						item.line_price = this.set_line_price	
					}
					
					if(this.set_supply_price){
						item.supply_price = this.set_supply_price
					}
					
					if(this.set_purchase_price){
						item.purchase_price = this.set_purchase_price
					}
					
					if(this.set_stock){
						item.stock = this.set_stock
					}
					
					
					if(this.set_bar_code){
						item.bar_code = this.set_bar_code
					}
					
				})
			},
			//添加子规格
			addvalue(index) {
				this.specs[index].spec_value.push('');
			},
			//删除子规格
			delvalue(index, i) {
				this.specs[index].spec_value.splice(i, 1);
			},
			//添加规格项
			addspec() {
				this.specs.push({ name: '', spec_value: [''] });
			},
			//删除规格项目
			delspec(index) {
				this.specs.splice(index, 1);
			},
		
			
		},
		mounted(){
			
		},
	}
</script>

<style lang="less" scoped>
#xskSku2{
	// min-height: calc(100vh - 110px);
}	

.specbox {
		position: relative;
		padding: 30px 0 10px 0;
		border-bottom: 1px solid #e9edef;
		.title {
			margin-bottom: 20px;
		}
		.value {
			position: relative;
			padding-left: 50px;
			display: flex;
			align-items: center;
			.icons {
				position: absolute;
				top: -10px;
				left: 16px;
				width: 26px;
				height: 26px;
				border: 1px solid #b8b9bd;
				border-top: none;
				border-right: none;
			}
			.inputbox {
				width: 160px;
				margin: 0 20px 20px 0;
				position: relative;
				.close {
					display: none;
					color: #c0c4cc;
					font-size: 18px;
					position: absolute;
					right: -8px;
					top: -8px;
					cursor: pointer;
				}
				&:hover {
					.close {
						display: block;
					}
				}
			}
			.addbtn {
				width: auto !important;
				font-size: 14px;
				color: #1989fa;
				cursor: pointer;
			}
		}
		.closeicon {
			display: none;
			font-size: 20px;
			color: #b8b9bd;
			position: absolute;
			top: 20px;
			right: 0;
		}
		&:hover {
			.closeicon {
				display: block;
			}
		}
	}
	.addbox {
		padding: 20px 0;
		color: #2d8cf0;
		font-size: 14px;
		border-bottom: 1px solid #e9edef;
		span {
			cursor: pointer;
		}
	}
	.allset{
		padding: 20px 0;
		border-bottom: 1px solid #e9edef;
		.plinput{
			 width: 130px;
			 margin-right: 14px;
		}
	}

	
</style>
