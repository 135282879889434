<template>
	<!-- <div class="mainbox" :style="{width:$route.meta.notright?'100%':'calc(100% - 210px)'}" > -->
	<div class="mainbox">
		<recom></recom>
	
		<div class="footbox">
			{{userinfo&&userinfo.admin_bottom_text?userinfo.admin_bottom_text:`Powered by 亿嘉欣 &copy; 2015-${year>2015?year:2020}`}}
			
		</div>
	</div>
</template>

<script>
import recom from './recom.vue'	
export default {
	name: 'asidenav',
	components:{recom},
	data() {
		return {
			year:new Date().getFullYear(),
			userinfo:''
		};
	},
	created() {
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
	},
	watch:{
			
	}
}
</script>

<style lang="less" scoped>
	.mainbox{
		// float: right;
		padding: 10px;
		
		.mainbox{
			padding: 0 !important;
			// .footbox{
			// 	display: none;
			// }
		}
	}
	.footbox{
		margin-top: 10px;
		width: 100%;
		text-align: center;
		font-size: 12px;
		line-height: 30px;
		color: #b8b9bd;
	}
</style>
