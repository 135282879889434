//全局组件注册
import xskPicturemanage from './xskManage/xskPicturemanage.vue' //相册管理
import xskManage from './xskManage/xskManage.vue' //相册管理
import xskSku from './xskSku/xskSku.vue' //笛卡尔积合并多规格
import xskSku2 from './xskSku2/xskSku2.vue' //笛卡尔积合并多规格第二版
import xskAddpick from './xskAddpick/xskAddpick.vue' //省市区选择器
import xskLogistics from './xskLogistics/xskLogistics.vue' //省市区选择器
import xskUploadQn from './xskUploadQn/xskUploadQn.vue' //上传文件到七牛云
import xskCascader from './xskCascader/xskCascader.vue' //省市区多选器
import xskTabs from './xskTabs/xskTabs.vue' //省市区多选器
import xskUploadXlsx from './xskUploadXlsx/xskUploadXlsx.vue' //读取excel
import xskPopup from './xskPopup/xskPopup.vue' //对话框
import xskVideoManage from './xskVideoManage/xskVideoManage.vue' //视频管理
import xskTreeTransfer from './xskTreeTransfer/xskTreeTransfer.vue' //树形穿梭框

function plugins(Vue){
	    Vue.component("xsk-picturemanage",xskPicturemanage)
	Vue.component("xsk-manage",xskManage)
	Vue.component("xsk-sku",xskSku)
	Vue.component("xsk-sku2",xskSku2)
	Vue.component("xsk-addpick",xskAddpick)
	Vue.component("xsk-logistics",xskLogistics)
	Vue.component("xsk-uploadqn",xskUploadQn)
	Vue.component("xsk-cascader",xskCascader)
	Vue.component("xsk-tabs",xskTabs)
	Vue.component("xsk-upload-xlsx",xskUploadXlsx)
	Vue.component("xskPopup",xskPopup)
	Vue.component("xskVideoManage",xskVideoManage)
	Vue.component("xsk-tree-transfer",xskTreeTransfer)
}

export default plugins