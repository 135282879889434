<template>
  <div class="plugins">
    <div>
      <div class="headsty">组件样式</div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">组件背景:</div>
        <el-radio-group v-model="plugins_data.style.box_bg_type">
          <el-radio :label="2">颜色</el-radio>
          <el-radio :label="1">透明</el-radio>
        </el-radio-group>
      </div>
      <div class="box" v-if="plugins_data.style.box_bg_type == 2">
        <div class="label">组件背景色:</div>
         <!-- color-format="rgb" -->
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.box_background"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.box_background"
          style="width: 100px; margin-right: 6px"
        ></el-input>
        <el-button
          plain
          size="small"
          @click="plugins_data.style.box_background = '#FFF5F3'"
          >重置</el-button
        >
      </div>
      <div class="box">
        <div class="label">文字颜色:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.text_color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.text_color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
        <el-button
          plain
          size="small"
          @click="plugins_data.style.text_color = '#F202002'"
          >重置</el-button
        >
      </div>
      <div class="linesty"></div>
      <div>
        <div class="imageContent">
          <div class="imageContent-item">
            <div class="imagebox" @click="openUpload(1)">
              <el-image
                class="image"
                v-if="plugins_data.data.left_icon"
                :src="plugins_data.data.left_icon"
                fit="cover"
              ></el-image>
              <div class="addbox" v-else>
                <i class="el-icon-plus addicon"></i>
                <div class="addtext">添加图片</div>
              </div>
            </div>
            <div class="center">定位图标</div>
          </div>

          <div class="imageContent-item">
            <div class="imagebox" @click="openUpload(4)">
              <el-image
                class="image"
                v-if="plugins_data.data.code_icon"
                :src="plugins_data.data.code_icon"
                fit="cover"
              ></el-image>
              <div class="addbox" v-else>
                <i class="el-icon-plus addicon"></i>
                <div class="addtext">添加图片</div>
              </div>
            </div>
            <div class="center">搜索图标</div>
          </div>

          <div class="imageContent-item">
            <div class="imagebox" @click="openUpload(2)">
              <el-image
                class="image"
                v-if="plugins_data.data.msg_icon"
                :src="plugins_data.data.msg_icon"
                fit="cover"
              ></el-image>
              <div class="addbox" v-else>
                <i class="el-icon-plus addicon"></i>
                <div class="addtext">添加图片</div>
              </div>
            </div>
            <div class="center">消息图标</div>
          </div>
          <div class="imageContent-item">
            <div class="imagebox" @click="openUpload(3)">
              <el-image
                class="image"
                v-if="plugins_data.data.code_icon"
                :src="plugins_data.data.code_icon"
                fit="cover"
              ></el-image>
              <div class="addbox" v-else>
                <i class="el-icon-plus addicon"></i>
                <div class="addtext">添加图片</div>
              </div>
            </div>
            <div class="center">会员图标</div>
          </div>
        </div>
      </div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.margin_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.margin_top"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.margin_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.margin_left"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.margin_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.margin_bottom"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_radius_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_radius_top"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.box_radius_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.box_radius_bottom"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右内边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.padding_left"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上下内边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_top"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model="plugins_data.style.padding_top"
        >
          <template slot="append"> PX </template>
        </el-input>
      </div>
    </div>

    <xsk-manage
      :managehide.sync="imgshow"
      is_rest
      @confirm="pickimg"
    ></xsk-manage>
  </div>
</template>

<script>
import { eltips } from "@/util/util.js";
export default {
  name: "storechoose",
  data() {
    return {
      upload_type: "",
      imgshow: false,
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  methods: {
    openUpload(type) {
      this.upload_type = type;
      this.imgshow = true;
    },
    //选择图片
    pickimg(e) {
		console.log(e)
      if (e.imgobjs.length > 0) {
        if (this.upload_type == 1) {
          this.plugins_data.data.left_icon = e.imgobjs[0].qiniu_url;
        }
        if (this.upload_type == 2) {
          this.plugins_data.data.msg_icon = e.imgobjs[0].qiniu_url;
        }
        if (this.upload_type == 3) {
          this.plugins_data.data.code_icon = e.imgobjs[0].qiniu_url;
        }
        if (this.upload_type == 4) {
          this.plugins_data.data.search_icon = e.imgobjs[0].qiniu_url;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";
.center {
  text-align: center;
}
// ::v-deep.el-image__error{
// 	font-size: 12px;
// }
/deep/.el-image__error{
	font-size: 12px;
}
.imageContent {
  display: flex;
  padding-bottom: 20px;
  margin: 0 -10px;
  .imageContent-item {
	  margin: 0 10px;
    .center {
      line-height: 20px;
	font-size: 12px;
    }
  }
}
.imagebox {
//   flex: 0 0 80px;
  width: 55px;
  height: 55px;
  border: 1px dashed #f0f0f0;
  .image {
    width: 100%;
    height: 100%;
  }
  .addbox {
    width: 100%;
    height: 100%;
    border: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .addicon {
      font-size: 20px;
      color: #1989fa;
    }
    .addtext {
      margin-top: 4px;
      font-size: 12px;
      color: #999999;
    }
  }
}
</style>
