<template>
  <div>
    <div
      v-if="$route.meta.layout && $route.meta.layout != 0"
      style="position: relative"
    >
      <div class="leftbox" ref="leftbox">
        <asidenav></asidenav>
      </div>
      <div
        class="rightbox"
        :style="{
          'margin-left': leftmargin + 'px',
          width: 'calc(100% - ' + leftmargin + 'px)',
        }"
      >
        <headernav class="fixed"></headernav>
        <mainbox class="main"></mainbox>
        <!-- <rightbox class="rightsty" v-if="!$route.meta.notright"></rightbox> -->
      </div>
    </div>

    <router-view v-else />
  </div>
</template>

<script>
import headernav from "./components/headernav.vue";
import asidenav from "./components/asidenav.vue";
import mainbox from "./components/mainbox.vue";
import rightbox from "./components/rightbox.vue";
export default {
  name: "layout",
  components: { headernav, asidenav, mainbox, rightbox },
  data() {
    return {
      leftmargin: 100,
      test: 1,
    };
  },
  created() {
    if (this.$route.matched.length > 1) {
      this.leftmargin = 260;
    } else {
      this.leftmargin = 110;
    }
  },
  watch: {
    "$store.state.asideWidth": function (newflag, oldflag) {
      if (newflag == 50) {
        this.leftmargin = 250;
      } else {
        this.leftmargin = 100;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.leftbox {
  display: inline-block;
  vertical-align: top;
  min-width: 100px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  mine-height: 100vh;
  background: #1c2c3c;
}
.rightbox {
  // float: left;
  margin-left: 100px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.fixed {
  width: calc(100% - 100px);
  position: fixed;
  left: 100px;
  top: 0;
  z-index: 1001;
}
.main {
  position: relative;
  top: 50px;
}
.rightsty {
  position: fixed;
  right: 10px;
  top: 60px;
}
</style>
