<template>
	<div class='plugins'>
		<div v-loading="plugins_data.dataLoading">
			<div class="headsty">内容</div>
			<div class="box" v-if="total>0" v-loading="totalLoading">
				<div class="label">展示条数:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.data.limit" :max="total"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.data.limit">
					<template slot="append">条</template>
				</el-input>
			</div>
			<div class="box" v-else v-loading="totalLoading">
				<div class="label">展示条数:</div>
				<el-button type="text" @click="toList">未获取到图文列表，快去添加吧</el-button>
			</div>
		</div>
		
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">背景颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background"></el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">列表间隔:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.margin_botton" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.margin_botton">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';
	export default {
		name: 'graphicData',
		components: { draggable },
		data() {
			return {
				imgshow:false,
				pickIndex:null,
				total:0,
				totalLoading:false,
			};
		},
		props: {
			plugins_data: { type: Object },
		},
		created() {
			this.getList()
		},
		methods:{
			toList(){
				this.$router.push({path:'/application'})
			},
			getList(){
				this.totalLoading = true
				this.axios.get('/api/public/admin/articles',{params:{status:1}}).then(res=>{
					this.totalLoading = false
					if(res.data.total>5){
						this.total = 5
					}else{
						this.total = res.data.total
					}
				})
			},
		}
	};
</script>

<style>
	#xskswiper .el-input.is-disabled .el-input__inner{
		color: #333 !important;
	}
</style>
<style lang="less" scoped>
	@import "../../style/pluginsCommon.css";
	
	.listbox{
		.list{
			padding: 10px;
			background: #F4F6F8;
			border: 1px solid #E9EDEF;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			position: relative;
			.icon{
				flex: 0 0 24px;
				margin-right: 10px;
				font-size: 24px;
			}
			.imagebox{
				flex: 0 0 98px;
				width: 98px;
				height: 98px;
				margin-right: 10px;
				.image{
					width: 100%;
					height: 100%;
				}
				.addbox{
					width: 100%;
					height: 100%;
					border: 1px solid #e3e3e3;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					.addicon{
						font-size: 20px;
						color: #1989fa;
					}
					.addtext{
						margin-top: 4px;
						font-size: 12px;
						color: #999999;
					}
				}
			}
			.right{
				flex: 1;
				.addlink{
					height: 32px;
					line-height: 32px;
					border: 1px solid #dcdee2;
					color: #409eff;
					padding: 0 10px;
					box-sizing: border-box;
					border-radius: 4px;
					cursor: pointer;
					&:hover{
						border-color: #409eff;
					}
				}
				.tips{
					color: #999999;
					font-size: 12px;
				}
				.changebtn{
					color: #409eff;
					cursor: pointer;
				}
				.linkIcon{
					color: #409eff;
					font-size: 16px;
				}
			}
			.delicon{
				font-size: 20px;
				color: rgba(0,0,0,.3);
				position: absolute;
				right: -10px;
				top: -10px;
				display: none;
				cursor: pointer;
			}
			&:hover{
				.delicon{
					display: block;
				}
			}
		}
	}

	.addbtn{
		line-height: 32px;
		text-align: center;
		border: 1px solid #dcdee2;
		color: #409eff;
		margin-bottom: 20px;
		&:hover{
			border-color: #409eff;
		}
	}
</style>
