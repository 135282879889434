<template>
	<div class="noticebox" 
		:style="{
		'padding':plugins_data.style.padding_top/2+'px '+plugins_data.style.padding_left/2+'px '+plugins_data.style.padding_bottom/2+'px',
		'background':plugins_data.style.background
		}">
			<div class="iconbox">
				<el-image style="width: 100%;height: 100%;" :src="plugins_data.data.image" fit="cover"></el-image>
			</div>
			<div class="notice">
				<el-carousel  height="20px" direction="vertical" autoplay loop indicator-position="none">
				    <el-carousel-item v-for="(item,index) in plugins_data.data.list" :key="index">
					  <div :style="{'color':plugins_data.style.color}">{{ item.msg }}</div>
				    </el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'noticeView',
	data() {
		return {
			list:[],
		};
	},
	props: {
		plugins_data: { type: Object }
	},
};
</script>

<style scoped lang="less">
	.noticebox{
		display: flex;
		align-items: center;
		.iconbox{
			flex: 0 0 30px;
			width: 30px;
			height: 30px;
			margin-right: 10px;
		}
		.notice{
			flex: 1;
		}
	}
</style>
