<template>
	<div id="pluginswiper">
		<div class="swiper" :style="{'paddingTop':plugins_data.style.padding_top/2+'px','paddingBottom':plugins_data.style.padding_bottom/2+'px'}">
			<swiper ref="mySwiper" :options="swiperOption" style="z-index: 0;">
			    <swiper-slide v-for="(item,index) in plugins_data.banner" :key="index">
					<div class="imgbox" :style="{'paddingLeft':plugins_data.style.padding_left/2+'px','paddingRight':plugins_data.style.padding_left/2+'px'}">
						<!-- <el-image class="img" :src="item.images" fit="cover"></el-image> -->
						<img class="img" :style="{
							'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
						}" :src="item.images||require('assets/images/img.png')" alt="">
					</div>
				</swiper-slide>
			    <div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
	</div>
</template>

<script>
	import { swiper, swiperSlide } from "vue-awesome-swiper";
	import "swiper/swiper-bundle.css";
	export default {
		name: 'swiperView',
		components: {
		    swiper,
		    swiperSlide
		},
		data() {
			return {
			  swiperOption: {
				autoHeight: false,
				// 显示分页
				pagination: {
				  el: ".swiper-pagination",
				  clickable :true,
				}
			  }
			};
		},
		props: {
			plugins_data: { type: Object },
		},
		computed: {
		    swiper() {
				return this.$refs.mySwiper.swiper;
			}
		},
		mounted() {
			this.swiper.allowTouchMove = false
		},
	};
</script>

<style lang="less" scoped>
	.swiper{
		padding: 20px 0;
		.imgbox {
		  .img{
				width: 100%;
				box-sizing: border-box;
			}
		} 
		.bullet-class{
			width: 20px;
			height: 20px;
			display: inline-block;
			border-radius: 50%;
			background: #FF0000;
		}
		.bullet-active-class{
			background: #000000;
		}
	}
	
</style>
