<!--
stocks 笛卡尔积生成的规格列表显示
specinfo 规格名称、值等赋值显示
is_disabled 是否禁用 默认fasle
@changeData 父组件获取回传sku信息 

组件依赖 element-ui
亿嘉欣Saas组件库 多规格表格 --- 2020.10.6  
 -->

<template>
	<div id="xskSku">
		<div class="headbox">
			<div class="specbox" v-for="(item, index) in specs" :key="index">
				<i v-if="index != 0" class="closeicon el-icon-error" @click="delspec(index)"></i>
				<div class="title"><el-input style="width: 160px;" v-model="item.name" placeholder="如颜色,尺码,大小"></el-input>
				</div>
				<div class="value">
					<div class="icons"></div>
					<div class="inputbox" v-for="(values, i) in item.value" :key="i">
						<el-input v-model="item.value[i]" placeholder="请填写自规格,如L,M"></el-input>
						<i v-if="i != 0" class="close el-icon-circle-close" @click="delvalue(index, i)"></i>
					</div>
					<div class="addbtn inputbox" @click="addvalue(index)">+添加规格值</div>
				</div>
			</div>
		</div>

		<div class="addbox"><span @click="addspec">+添加规格项</span></div>
		<div class="allset">
			<span style="margin-right: 20px;">批量设置:</span>
			<el-input class="plinput" v-model="set_price" placeholder="售卖价"></el-input>
			<el-input class="plinput" v-model="set_line_price" placeholder="划线价"></el-input>
			<el-input class="plinput" v-model="set_cost_price" placeholder="成本价"></el-input>
			<el-input class="plinput" v-model="set_stock" placeholder="库存"></el-input>
			<el-input class="plinput" v-model="set_give_integral" placeholder="赠送积分"></el-input>
			<el-input class="plinput" v-model="set_integral" placeholder="兑换积分"></el-input>
			<el-input class="plinput" v-model="set_barcode" placeholder="商品条码"></el-input>
			<el-button style="margin-right: 20px;" type="primary" size="small" plain @click="setall">应用</el-button>
		</div>

		<div class="tablebox">
			<el-table :data="tableArr" style="width: 100%">
				<el-table-column v-for="(item, index) in specs" :key="index" :prop="'key' + index"
					:label="item.name || '--'"></el-table-column>
				<el-table-column label="售卖价">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="划线价">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.line_price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="成本价">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.cost_price"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="库存">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.stock"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="赠送积分">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.give_integral"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="兑换积分">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.integral"></el-input>
					</template>
				</el-table-column>

				<el-table-column label="商品条码">
					<template slot-scope="scope">
						<el-input type="text" v-model="scope.row.barcode"></el-input>
					</template>
				</el-table-column>

			</el-table>
		</div>
	</div>
</template>

<script>
import { eltips, SpecCartesian } from '@/util/util.js';
export default {
	name: 'xskSku',
	data() {
		return {
			specs: [{ name: '', value: [''] }],
			tableArr: [{ give_integral: "", barcode: '', cost_price: '', key0: '--', line_price: '', price: '', stock: '', title0: '--' ,integral:""}],
			spanArr: [],
			pos: 0,
			callbackArr: [],
			set_price: '',
			set_line_price: '',
			set_cost_price: '',
			set_stock: '',
			set_barcode: '',
			set_give_integral: "",
			set_integral:""
		};
	},
	props: {
		//组合后规格
		stocks: {
			type: Array,
			default: () => [],
		},
		//规格
		specinfo: {
			type: Array,
			default: () => [],
		},
		//是否禁用
		is_disabled: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		stocks: {
			handler(val) {
				if (val.length > 0) {
					this.tableArr = JSON.parse(JSON.stringify(val))
				}
			},
			deep: true,
			immediate: true
		},
		specinfo: {
			handler(val) {
				if (val.length > 0) {
					this.specs = JSON.parse(JSON.stringify(val))
				}
			},
			deep: true,
			immediate: true
		},
		specs: {
			deep: true,
			immediate: true,
			handler: function (newV, oldV) {
				let arr = SpecCartesian(JSON.parse(JSON.stringify(newV)));

				let newarr = [];
				arr.forEach(item => {
					let obj = {};
					let spec = item.split(',');
					let specname = ''
					spec.forEach((s, index) => {
						obj['key' + index] = s;
						if (specname) {
							specname = specname + '-' + s
						} else {
							specname = s
						}
					});

					this.specs.forEach((val, i) => {
						obj['title' + i] = val.title;
					});

					obj.name = specname
					obj.price = '';
					obj.line_price = '';
					obj.cost_price = '';
					obj.stock = '';
					obj.barcode = '';
					obj.give_integral = '';
					obj.integral = '';


					this.tableArr.forEach(arrs => {

						if (arrs.name === obj.name) {
							console.log('检测到存在的sku', arrs)
							obj.price = arrs.price
							obj.line_price = arrs.line_price
							obj.cost_price = arrs.cost_price
							obj.stock = arrs.stock
							obj.barcode = arrs.barcode
							obj.give_integral = arrs.give_integral
							obj.integral = arrs.integral
						}
					})

					newarr.push(obj);
				});
				this.tableArr = newarr;
				this.$emit('changeData', {
					spec: this.specs,
					stocks: this.tableArr
				})
			}
		}
	},
	methods: {
		//批量设置
		setall() {
			this.tableArr.forEach(item => {
				if (this.set_price) {
					item.price = this.set_price
				}

				if (this.set_line_price) {
					item.line_price = this.set_line_price
				}

				if (this.set_cost_price) {
					item.cost_price = this.set_cost_price
				}

				if (this.set_stock) {
					item.stock = this.set_stock
				}


				if (this.set_barcode) {
					item.barcode = this.set_barcode
				}
				if (this.set_give_integral) {
					item.give_integral = this.set_give_integral
				}
				if (this.set_integral) {
					item.integral = this.set_integral
				}



			})
		},
		//添加子规格
		addvalue(index) {
			this.specs[index].value.push('');
		},
		//删除子规格
		delvalue(index, i) {
			this.specs[index].value.splice(i, 1);
		},
		//添加规格项
		addspec() {
			this.specs.push({ name: '', value: [''] });
		},
		//删除规格项目
		delspec(index) {
			this.specs.splice(index, 1);
		},
	}
};
</script>

<style scoped lang="less">
#xskSku {
	background: #f4f6f8;
	padding: 0 20px 30px;
	margin-left: 72px;
	width: 1720px;

}

.specbox {
	width: 100%;
	position: relative;
	padding: 30px 0 10px 0;
	border-bottom: 1px solid #e9edef;


	.title {
		margin-bottom: 20px;
	}

	.value {
		width: 100%;
		position: relative;
		padding-left: 50px;
		display: flex;
		align-items: center;
		flex-wrap:nowrap;
		flex-wrap:wrap;
		.icons {
			position: absolute;
			top: -10px;
			left: 16px;
			width: 26px;
			height: 26px;
			border: 1px solid #b8b9bd;
			border-top: none;
			border-right: none;
		}

		.inputbox {
			min-width: 160px !important;
			margin: 0 20px 20px 0;
			position: relative;
		

			.close {
				display: none;
				color: #c0c4cc;
				font-size: 18px;
				position: absolute;
				right: -8px;
				top: -8px;
				cursor: pointer;
			}

			&:hover {
				.close {
					display: block;
				}
			}
		}

		.addbtn {
			width: auto !important;
			font-size: 14px;
			color: #1989fa;
			cursor: pointer;
		}
	}

	.closeicon {
		display: none;
		font-size: 20px;
		color: #b8b9bd;
		position: absolute;
		top: 20px;
		right: 0;
	}

	&:hover {
		.closeicon {
			display: block;
		}
	}
}

.addbox {
	padding: 20px 0;
	color: #2d8cf0;
	font-size: 14px;
	border-bottom: 1px solid #e9edef;

	span {
		cursor: pointer;
	}
}

.allset {
	padding: 20px 0;
	border-bottom: 1px solid #e9edef;

	.plinput {
		width: 130px;
		margin-right: 14px;
	}
}
</style>
