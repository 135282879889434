<template>
	<div id="hotarea" :style="{
		'paddingLeft':plugins_data.style.margin_left*((375-plugins_data.style.margin_left-plugins_data.style.margin_left)/750)+'px',
		'paddingRight':plugins_data.style.margin_left*((375-plugins_data.style.margin_left-plugins_data.style.margin_left)/750)+'px',
	}">
		<div class="contentbox" :style="{
				'marginTop':plugins_data.style.margin_top*((375-plugins_data.style.margin_left-plugins_data.style.margin_left)/750)+'px',
				'marginBottom':plugins_data.style.margin_bottom*((375-plugins_data.style.margin_left-plugins_data.style.margin_left)/750)+'px',
				'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
			}">
			<el-image class="img" ref="elimg" :src="plugins_data.data.image||require('assets/icon/goods_col2.png')" @load="imgload"></el-image>
			<div class="box" v-for="item in plugins_data.data.list" :key="item.key" :style="{
				'width':item.width*(nowWidth/plugins_data.data.initWidth)+'px',
				'height':item.height*(nowHeight/plugins_data.data.initHeight)+'px',
				'top':item.top*(nowHeight/plugins_data.data.initHeight)+'px',
				'left':item.left*(nowWidth/plugins_data.data.initWidth)+'px',
			}">
				<div class="text">{{item.link?item.link.data.name:'选择链接'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'hotarea',
	data() {
		return {
			initHeight:1,
			initWidth:1,
			nowHeight:1,
			nowWidth:1,
		};
	},
	props: {
		plugins_data: { type: Object },
	},
	watch:{
		'plugins_data.style.margin_left':{
			handler(val) {
				if(this.initHeight!=1){
					this.nowHeight = this.$refs.elimg.$el.offsetHeight
				}
				if(this.initWidth!=1){
					this.nowWidth = this.$refs.elimg.$el.offsetWidth
				}
			},
			immediate: true
		}
	},
	computed: {
	　　marginLeft(){
	　　　　return this.plugins_data.style.margin_left
	　　}
	},
	methods:{
		imgload(e){
			this.initHeight = this.$refs.elimg.$el.offsetHeight
			this.initWidth = this.$refs.elimg.$el.offsetWidth
			this.nowHeight = this.$refs.elimg.$el.offsetHeight
			this.nowWidth = this.$refs.elimg.$el.offsetWidth
		}
	}
};
</script>

<style scoped lang="less">
#hotarea{
	.contentbox{
		overflow: hidden;
		position: relative;
		.img{
			width: 100%;
			height: auto;
			display: block;
		}
		.box{
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			padding: 5px;
			background: rgba(45,140,240,0.6);
			cursor: default;
			overflow: hidden;
			.text{
				font-size: 12px;
				color: #FFFFFF;
			}
		}
	}
}
</style>
